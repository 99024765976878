import * as React from "react"
import { graphql } from "gatsby"
import logo from "../images/logo.svg"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div
        style={{
          position: "fixed",
          left: 0,
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          display: "flex",
          fontFamily: "Roboto",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 60,
            fontWeight: 600,
            color: "var(--color-primary)",
          }}
        >
          <span>4</span>
          <img src={logo} style={{ margin: 10 }} height={50} alt="logo" />
          <span>4</span>
        </div>
        <h2 style={{ fontWeight: 400 }}>This page doesn't exist.</h2>
        {/* <footer
          style={{
            textAlign: "center",
            fontFamily: "Roboto",
            position: "absolute",
            bottom: 22,
          }}
        >
          © {new Date().getFullYear()} CoderSchool
        </footer> */}
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
